<template>
  <div>
    <v-row>
      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">
          <v-card-title class="display-1">
            {{ _totalImpressions }}
          </v-card-title>
          <v-card-subtitle class="overline">
            Total Impressions
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">
          <v-card-title class="display-1">
            {{ _totalSpend }}
          </v-card-title>
          <v-card-subtitle class="overline"> Total Spend </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">
          <v-card-title class="display-1">
            {{ _totalClicks }}
          </v-card-title>
          <v-card-subtitle class="overline"> Total Clicks </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">
          <v-card-title class="display-1"> {{ _ctr }}% </v-card-title>
          <v-card-subtitle class="overline"> Total CTR </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  compactNumber,
  usCurrencyFormatterCompact,
} from "@/utils/numberFormatter.js";
export default {
  props: {
    app_campaigns_statistics: Array,
  },
  data() {
    return {
      totalImpressions: 0,
      totalSpend: 0,
      totalCTR: 0,
      totalClicks: 0,
    };
  },
  mounted() {},

  computed: {
    _app_campaigns_statistics() {
      return this.$store.state.dsp.app_campaigns_statistics;
    },
    _totalImpressions() {
      const impressions = this._app_campaigns_statistics.map((item) => {
        return item.total_impressions;
      });
      return compactNumber(this.sumArray(impressions));
    },
    _totalSpend() {
      const spend = this._app_campaigns_statistics.map((item) => {
        return Number(item.total_spend);
      });
      const totalSpend = this.sumArray(spend);
      // return usCurrencyFormatterCompact((totalSpend * 100) / 30); //added hack to return higher spend
      return usCurrencyFormatterCompact(totalSpend);
    },
    _totalClicks() {
      const clicks = this._app_campaigns_statistics.map((item) => {
        return item.total_clicks;
      });
      return compactNumber(this.sumArray(clicks));
    },
    _ctr() {
      // get average
      // const ctr = this._app_campaigns_statistics.map((item) => {
      //   return parseFloat(item.average_ctr);
      // });
      // const ctrNumber = ctr.length;
      // const ctrSum = ctr.reduce(
      //   (accumulator, currentValue) => accumulator + currentValue,
      //   0
      // );
      // return ctrSum / ctrNumber;

      // calculate total ctr
      const impressions = this._app_campaigns_statistics.map((item) => {
        return item.total_impressions;
      });

      const totalImpressions = this.sumArray(impressions);

      const clicks = this._app_campaigns_statistics.map((item) => {
        return item.total_clicks;
      });
      const totalClicks = this.sumArray(clicks);

      if (totalClicks === 0 && totalImpressions === 0) {
        return 0;
      } else {
        return ((totalClicks / totalImpressions) * 100).toFixed(2);
      }
    },
  },
  methods: {
    sumArray(array) {
      return array.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
    },
  },
};
</script>
